import React, { useState, useEffect } from 'react'
import JncLayout from '../layouts/JncLayout'
import { useSelector } from 'react-redux'
import JncBalances from '../components/dashboard/JncBalances'
import JncAccounts from '../components/dashboard/JncAccounts'
import JunoClientSnackbar from '../components/global/JunoClientSnackbar'
import JncTransactions from '../tables/transactions/JncTransactions'
import DashboardActions from '../components/dashboard/DashboardActions'
import { useJunoCommonFunctions } from '../../helpers'
import JncStatements from '../tables/statements/JncStatements'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import { NavLink } from 'react-router-dom'
import DashboardHeader from "../components/dashboard/DashboardHeader";
import JncCryptoDashboardPage from './JncCryptoDashboardPage'
import TransactionOverview from '../components/dashboard/TransactionOverview'
import MediaQuery from 'react-responsive'

export default function JncDashboardPage() {
    const { getBalances, getSavedAccounts, getStatements, getCryptoBalances } =
        useJunoCommonFunctions()
    const activeCurrencyPage = useSelector((state) => state.juno.activeCurrencyPage)
    const clientData = useSelector((state) => state.juno.clientData)
    const savedAccounts = useSelector((state) => state.juno.savedAccounts)
    const statementsData = useSelector((state) => state.juno.statements)
    const [isBalancesTab, setIsBalanceTab] = useState(true)


    useEffect(() => {
        if (clientData) {
            if (activeCurrencyPage === "crypto") {
                getCryptoBalances()
            }
            getBalances()
            getSavedAccounts()
            getStatements()
        }
    }, [clientData, activeCurrencyPage])

    return (
        <JncLayout>
            {activeCurrencyPage === "fiat" ?
                <div className="jncPage">
                    <AnimationOnScroll
                        animateIn="animate__fadeInUpCustom"
                        duration={1.2}
                    >
                        <DashboardHeader />
                      <MediaQuery minWidth={992}> <TransactionOverview /></MediaQuery>  
                        <DashboardActions />
                        <MediaQuery maxWidth={991.98}> <TransactionOverview /></MediaQuery>  
                        <div className="jncPage__order mt-3">
                            <div className="max-991 jncDashbTabs savedAccounts">
                                <div className="savedAccounts-tabs">
                                    <button
                                        type="button"
                                        className={`${isBalancesTab && 'active'}`}
                                        onClick={() => setIsBalanceTab(true)}
                                    >
                                        Balances
                                    </button>
                                    <button>Transaction Overview</button>
                                    <button
                                        type="button"
                                        className={`${!isBalancesTab && 'active'}`}
                                        onClick={() => setIsBalanceTab(false)}
                                    >
                                        Transaction history
                                    </button>
                                </div>
                            </div>
                            <JncBalances
                                isDashboardPage={true}
                                isBalancesTab={isBalancesTab}
                            />
                            <JncTransactions
                                showTablePagination={false}
                                isDashboard={true}
                                isBalancesTab={isBalancesTab}
                            />
                            <NavLink
                                className="jncDashbTabs__seeAll max-991"
                                to={
                                    isBalancesTab
                                        ? '/client/balances'
                                        : '/client/transactions'
                                }
                            >
                                See All
                            </NavLink>
                            {savedAccounts && savedAccounts.length > 0 && (
                                <JncAccounts isDashboardPage={true} />
                            )}
                            {statementsData && (
                                <JncStatements
                                    showTablePagination={false}
                                    isDashboardPage={true}
                                />
                            )}
                        </div>
                    </AnimationOnScroll>
                    <JunoClientSnackbar />
                </div>
                :
                <JncCryptoDashboardPage />
            }

        </JncLayout>
    )
}
