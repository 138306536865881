import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { apiService } from '../../../../common/apiCallService'
import dayjs from 'dayjs'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import { Restore, AccessTime, Check, Clear } from '@mui/icons-material'
import { convertFromStartOfDayUTC, convertToEndOfDayUTC } from '../../../helpers'

function TransactionOverview() {
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)
    const api_url = useSelector((state) => state.config.api_url)
    const clientData = useSelector((state) => state.juno.clientData)

    const dateOptions = useMemo(
        () => [
            { key: 'today', label: 'Today', treadText: 'Today' },
            { key: 'lastWeek', label: 'Last Week', treadText: 'this week' },
            { key: 'lastMonth', label: 'Last Month', treadText: 'this month' },
            { key: 'lastQuarter', label: 'Last Quarter', treadText: 'this quarter' },
            { key: 'lastYear', label: 'Last Year', treadText: 'this year' },
        ],
        []
    )
    const [isDatepickerOpen, setIsDatepickerOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [selectedDateOption, setSelectedDateOption] = useState(dateOptions[0])
    const [overviewData, setOverviewData] = useState([
        {amount: '0', key: 'total', label: 'Total',iconType: 'Restore', bgIcon: 'bg-[#D1E9FF]', position:0},
        {amount: '0', key: 'success', label: 'Completed',iconType: 'Check', bgIcon: 'bg-[#ECFDF3]', position:1},
        {amount: '0', key: 'processing', label: 'Processing',iconType: 'AccessTime', bgIcon:'bg-[#F4F4F5]', position:2},
        {amount: '0', key: 'failed', label: 'Failed',iconType: 'Clear', bgIcon:'bg-[#FEF3F2]', position:3},
    ])

   

    const icons = useMemo(
        () => ({
            Restore: <Restore fontSize="inherit" />,
            AccessTime: <AccessTime fontSize="inherit" />,
            Check: <Check fontSize="inherit" />,
            Clear: <Clear fontSize="inherit" />,
        }),
        []
    )

    const getFromDate = (key) => {
        const options = {
            today: dayjs(),
            lastWeek: dayjs().subtract(1, 'week').add(1, 'day'),
            lastMonth: dayjs().subtract(1, 'month').add(1, 'day'),
            lastQuarter: dayjs().subtract(3, 'month').add(1, 'day'),
            lastYear: dayjs().subtract(1, 'year').add(1, 'day'),
        }
        return options[key] || options.today
    }

    const fetchTransactionOverview = async (clientId, dateKey) => {
        const url = `${api_url || ''}/restapi/get-client-transactions-overview`
        const body = {
            clientId,
            from: convertFromStartOfDayUTC(getFromDate(dateKey)),
            to: convertToEndOfDayUTC(dayjs()),
            dateType: dateKey,
        }

        setIsLoading(true)

        try {
            let response = [];
            await apiService(url, body, async resp=> {response = resp}, async err => {console.log(err); throw err})

            if (response?.statusWiseTransactions?.length > 0) {
                const data = response.statusWiseTransactions;
                let newOverviewData = overviewData.map((item) => {
                   const _mt = data.find((x) => x.actualStatus === item.key);
                   return {
                       ...item,
                       amount: _mt?.transactionCount?.toString() || '0',
                       tradingInfo: `${_mt?.trend || '-0.00%'}  ${selectedDateOption.treadText}`,
                       tradingIcon:
                           _mt?.current >= _mt?.previous
                               ? `${imageBaseUrl}/common/upward_green_icon.svg`
                               : `${imageBaseUrl}/common/downward_red_icon.svg`,
                   };
                }).sort((a, b) => a.position - b.position)
                setOverviewData(newOverviewData)               
            }
        } catch (error) {
            console.error('Error fetching transaction overview:', error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleDateChange = (selctedKey) => {
        setSelectedDateOption(selctedKey)
    }

    useEffect(() => {
        if (clientData?.clientId) {
            fetchTransactionOverview(clientData.clientId, selectedDateOption.key)
        }
    }, [clientData, selectedDateOption])

    return (
        <div className="jncCard mt-2">
            <div className="flex w-full xs:flex-col md:flex-row">
                <h2 className="font-semibold leading-7 text-lg font-inter text-base xs:font-medium lg:font-semibold leading-6 text-left">
                    {`Transaction Overview`}
                </h2>
                <DatePicker
                    isOpen={isDatepickerOpen}
                    toggle={() => setIsDatepickerOpen(!isDatepickerOpen)}
                    options={dateOptions}
                    selectedKey={selectedDateOption.key}
                    onChange={handleDateChange}
                />
            </div>
            {!isLoading && overviewData.length > 0 ? (
                <div className="md:bg-white lg:bg-[#FAFAFA] xs:gap-3 sm:flex-nowrap md:flex-wrap overflow-auto mt-3 flex flex-nowrap md:gap-[8px] lg:gap-4 md:px-0 lg:pl-3 py-1.5 lg:rounded-lg">
                    {overviewData.map((data) => (
                        <OverviewCard
                            key={data.key}
                            data={data}
                            icons={icons}
                            index = {data.position}
                            imageBaseUrl={imageBaseUrl}
                            treadText = {selectedDateOption.treadText}
                        />
                    ))}
                </div>
            ) : (
                <p className="text-center text-gray-500 mt-4">{`Loading...`}</p>
            )}            
        </div>
    )
}

const DatePicker = ({ isOpen, toggle, options, selectedKey, onChange }) => (
    <div
        className="relative flex items-center border-2 rounded-lg md:ml-auto xs:ml-0 xs:mt-3 md:mt-0"
        onClick={toggle}
    >
        <div className="flex p-1.5 lg:w-[222px] md:w-[225px] justify-between xs:w-full">
            <CalendarTodayOutlinedIcon className="pl-1 text-base mr-2 text-[#51525C]" />
            <button className="text-sm font-semibold bg-transparent w-[80%] placeholder-gray-800 lg:font-bold">
                {options.find((opt) => opt.key === selectedKey)?.label}
            </button>
            <KeyboardArrowDownOutlinedIcon className="lg:text-[#18181B] lg:text-3xl" />
            {isOpen && (
                <div className="absolute top-full mt-2 bg-white border rounded-lg shadow-lg z-10 w-full">
                    <ul className="text-sm text-gray-700">
                        {options.map(({ key, label, treadText }) => (
                            <li
                                key={key}
                                className="py-2 px-4 hover:bg-gray-100 cursor-pointer"
                                onClick={() => {
                                    toggle()
                                    onChange({ key, label, treadText })
                                }}
                            >
                                {label}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    </div>
)

const OverviewCard = ({ data, icons , index,imageBaseUrl, treadText}) => (
    <div className={`xs:min-w-80 md:min-w-fit md:w-[49.4%] lg:w-[24%] bg-[#FAFAFA] flex py-3 xs:rounded-xl md:px-2 lg:pl-0 lg:pr-0 md:rounded-lg ${index !== 3 ? 'border-r-[1px]' : ''} lg:rounded-none border-gray-200`}>
        <div className="w-full xs:px-4">
            <div className="flex justify-between w-full">
                <div>
                    <h2 className="text-2xl xs:text-3xl font-bold leading-[32px]">
                        {data.amount}
                    </h2>
                    <p className="xs:text-sm lg:text-base leading-6">
                        {data.label}
                    </p>
                </div>
                <div className={`rounded-lg h-fit p-2 ${data.bgIcon}`}>
                    {icons[data.iconType]}
                </div>
            </div>
            <div className="flex mt-[10px]">
                <img src={data?.tradingIcon || `${imageBaseUrl}/common/downward_red_icon.svg`} alt="trend-icon" />
                <p className="text-sm text-[#70707B]">{data?.tradingInfo || `-0.00% this ${treadText}`}</p>
            </div>
        </div>
    </div>
)

export default TransactionOverview
